import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  ActivatedRoute,  Router,  NavigationStart,  NavigationEnd,} from '@angular/router';
@Component({
  selector: 'app-templatesubmitresponse',
  templateUrl: './templatesubmitresponse.component.html',
  styleUrls: ['./templatesubmitresponse.component.scss']
})
export class TemplatesubmitresponseComponent implements OnInit {

  constructor(private dialog:MatDialog,private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  cancel(){
    this.dialog.closeAll();
    this.router.navigate(['/online/MycontributionForm']);
  }
  
}
