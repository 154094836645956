export enum NEW_TEMPLATE_POPUP {
    width = "530px",
    top = "9%"
  }

  export enum PREVIEW_TEMPLATE_POPUP {
    width = "95vw",
    top = "2%",
    height="80vh"
  }
  export enum DEL_TEMPLATE_POPUP {
    width = "530px",
    top = "9%"
  }

